<template>
  <div>
    <b-container fluid>
      <b-row v-if="panelObj.incentiveFarmerLoader">
        <b-col class="text-center">
          <b-spinner variant="primary" label="Text Centered"></b-spinner>
          <br/>
          {{ panelObj.incentiveFarmerMesage }}
        </b-col>
      </b-row>
      <b-row  v-else-if="panelObj.profileStatus !== 2">
        <b-col>
           <!-- <profile-status-message :profileStatus="panelObj.profileStatus" :profileUrl="'/incentive-farmer/profile'" /> -->
           <b-card>
             <profile-status-msg :preConditionLoader="panelObj.incentiveFarmerLoader" :profileStatus="panelObj.profileStatus" :profile-url="'/incentive-farmer/profile'" :inForm="false"/>
           </b-card>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col>
            <b-card class="card my-card">
                <h3 class="text-white t-shadow">{{ $t('externalUserIrrigation.hello')}} <span class="text-info t-shadow" v-if="this.$i18n.locale === 'bn'">{{ user.name_bn }}</span> <span class="text-info t-shadow" v-if="this.$i18n.locale === 'en'">{{ user.name }}</span>!</h3>
                <h3 class="text-white t-shadow" v-if="this.$i18n.locale === 'bn'"><span class="text-danger">কৃষি মন্ত্রণালয়</span> এর <span class="text-warning">ইন্টিগ্রেটেড ডিজিটাল পরিষেবা ডেলিভারি প্ল্যাটফর্ম</span> এ আপনাকে স্বাগতম</h3>
                <h3 class="text-white t-shadow" v-if="this.$i18n.locale === 'en'">Welcome to <span class="text-warning">Integrated Digital Service Delivery Platform Service</span> of <span class="text-danger">Ministry of Agriculture</span></h3>
                <h4 class="text-white t-shadow">{{ $t('externalUserIrrigation.your_all_service_here') }} <i class="ion-heart text-danger"></i><div data-icon="i" class="icon"></div></h4>
            </b-card>
        </b-col>
    </b-row>
    </b-container>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import ProfileStatusMsg from '@/modules/external-user-service/irrigation/components/ProfileStatusMsg'
import { mapGetters } from 'vuex'

export default {
  components: { ProfileStatusMsg },
  data () {
    return {
      loadingState: false,
      overlay: true,
      user: {},
      profileStatus: '',
      approveStatus: '',
      series: [{
        name: 'Total',
        data: [45, 70, 49, 60]
      }],
      search: {
        warehouse_id: '',
        farmer_id: ''
      },
      approvalMessage: false,
      productStockSeriesData: [30, 70],
      deliveryScheduleList: [],
      showData: false,
      showHeading: false
    }
  },
  computed: {
    ...mapGetters({
      authUser: 'Auth/authUser',
      panelObj: 'ExternalUserIrrigation/panelObj'
    }),
    currentLocale () {
      return this.$i18n.locale
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('warehouse_service.crop_type'), class: 'text-left' },
          { label: this.$t('warehouse_service.crop_name'), class: 'text-left' },
          { label: this.$t('warehouse_service.delivery_date'), class: 'text-left' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'commodity_group_name_bn' },
          { key: 'commodity_name_bn' },
          { key: 'delivery_date' }

        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'commodity_group_name' },
          { key: 'commodity_name' },
          { key: 'delivery_date' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    },
    dataOption () {
        return {
            chart: {
                id: 'vuechart-example',
                type: 'bar'
            },
            plotOptions: {
              bar: {
                barHeight: '100%',
                distributed: true,
                horizontal: false,
                dataLabels: {
                  position: 'bottom'
                }
              }
            },
            labels: [
                this.$t('warehouseInfoService.totalSpace'),
                this.$t('warehouseInfoService.actualSpace'),
                this.$t('warehouseInfoService.filledSpace'),
                this.$t('warehouseInfoService.freeSpace')
                ],
            colors: ['#666666', '#3333cc', '#ff0000', '#009900']
        }
    },
    productStockLabels () {
      return {
        chart: {
          width: 200,
          type: 'pie'
        },
        labels: [this.$t('warehouse_service.totalStockQuin'), this.$t('warehouse_service.productStockQuin')],
        colors: ['#0070C0', '#002060'],
        legend: {
          position: 'bottom'
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      }
    }
  },
  mounted () {
    core.index()
  },
  methods: {
    productStockSeries () {
      return this.productStockSeriesData
    }
  }
}
</script>
