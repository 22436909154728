<template>
  <div>
      <div v-if="preConditionLoader" class="text-center">
        {{ $t('externalUserIrrigation.checking_pre_condition') }}
        <br/>
        <b-spinner variant="primary" label="Text Centered"></b-spinner>
        <br/>
        <br/>
        <hr/>
      </div>
      <div v-else>
        <!-- {{ `preConditionLoader ${preConditionLoader}, profileStatus= ${profileStatus} inForm=${inForm}`}} -->
        <div v-if="profileStatus <= 3" class="text-danger">
            <div v-if="hasArrow" class="arrow">
              <div class="outer"></div>
              <div class="inner"></div>
            </div>
            <div :class="{ 'message-body': hasArrow, 'message-body-center': !hasArrow }">
              <p class="mb-0 h5"><i class="ri-alert-fill mr-2"></i>
                <span v-if="profileStatus === 0">
                  {{ inForm ? $t('externalPanel.profile_complete_msg_part1'): $t('externalPanel.profile_complete_msg_part1a') }}
                  <router-link :to="{ path: profileUrl }">
                    {{ $t('externalPanel.profile_complete_msg_part2') }}
                  </router-link>
                  {{ $t('externalPanel.profile_complete_msg_part3') }}
                  {{ needApproval ? $t('externalPanel.profile_complete_msg_part4') : '' }}
                </span>
                <span v-if="profileStatus === 1">
                  {{ $t('externalPanel.profile_approval_msg') }}
                </span>
                <span v-if="profileStatus === 2">
                  {{ $t('externalPanel.profile_rejected_msg') }}
                </span>
                <span v-if="profileStatus === 3">
                  {{ $t('externalPanel.unauthorized_message') }}
                </span>
              </p>
            </div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
    props: {
      preConditionLoader: {
        type: Boolean,
        required: true
      },
      /** profileStatus values 0 = no profile or draft, 1 = profile created but not approved (wait for admin approval)
       * 2 = Rejected or canceled, 3 = Unauthorized, 4 or any other integer value means profile approved or suppressed message
       */
      profileStatus: {
          type: Number,
          required: true
      },
      profileUrl: {
          type: String,
          required: false
      },
      /** if the message need to display inside the form linked to the service url */
      inForm: {
          type: Boolean,
          default: false
      },
      needApproval: {
        type: Boolean,
        default: false
      }
    },
    computed: {
        hasArrow () {
            return (this.profileStatus === 0 && this.inForm === false)
        }
    }
}
</script>

<style>
  /* Message box starts here */
  .box-message {
      clear: both;
      position: relative;
  }

  .box-message .arrow {
      width: 12px;
      height: 20px;
      overflow: hidden;
      position: relative;
      float: left;
      top: 6px;
      right: -1px;
  }

  .box-message .arrow .outer {
      width: 0;
      height: 0;
      border-right: 20px solid #3dbb58;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      position: absolute;
      top: 0;
      left: 0;
  }

  .box-message .arrow .inner {
      width: 0;
      height: 0;
      border-right: 20px solid #ffffff;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      position: absolute;
      top: 0;
      left: 2px;
  }

  .box-message .message-body {
      float: left;
      height: auto;
      border: 1px solid #CCC;
      background-color: #eefff2;
      border: 1px solid #3dbb58;
      padding: 6px 8px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      -o-border-radius: 5px;
      border-radius: 5px;
  }

  .box-message .message-body-center {
      width: 60%;
      margin: auto;
      height: auto;
      border: 1px solid #CCC;
      background-color: #eefff2;
      border: 1px solid #3dbb58;
      padding: 6px 8px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      -o-border-radius: 5px;
      border-radius: 5px;
  }

  .box-message .message-body p {
      margin: 0;
      border: 0;
  }

  .box-message .message-body-center p {
      margin: 0;
      border: 0;
  }
</style>
